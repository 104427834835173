import $ from 'jquery'
let scrolled = 0
let scroll = 0
let direction = 'up'
let currentSectionIndex = 0


const logo = document.querySelector('.home__logo')
const homeSections = [...document.querySelectorAll('.home__section')]

const slideToNextArea = e => {
  const body = $("html");
  if (currentSectionIndex !== homeSections.length - 1) {
    body.animate({ scrollTop: homeSections[currentSectionIndex + 1].offsetTop }, {duration: 500, easing: 'linear'});
  }
}

const cursorArea = document.querySelector('.home__cursor-area')
if (cursorArea) { cursorArea.addEventListener('click', slideToNextArea) }


window.onscroll = () => {
  
  if (scroll > window.scrollY) {
    if (direction === 'up') {
      scrolled++
    } else {
      direction = 'up'
      scrolled = 0
    }
  } else {
    if (direction === 'down') {
      scrolled++
    } else {
      direction = 'down'
      scrolled = 0
    }
  }
  scroll = window.scrollY

  if(scroll < 20) {
    direction = 'up'
  }
  
  direction === 'down'
    ? document.body.classList.add('is-scrolled')
    : document.body.classList.remove('is-scrolled')

  if (window.innerWidth > 768 && logo) {
    if (window.scrollY > (window.innerHeight/2) && window.innerWidth > 768) {
      logo.style.color = '#222'
    } else {
      logo.style.color = 'white'
    }

  }
}

const sections = [...document.querySelectorAll('.home__section')];
const options = {
  root: null,
  rootMargin: `0px 0px -${window.innerHeight / 2}px 0px`
}
function onIntersection(sections) {
  sections.forEach(section => {
    if (section.isIntersecting) { 
      switch (section.target.dataset.index) {
        case 'start':
          currentSectionIndex = 0
          document.getElementById('image__1').classList.remove('image-hide')
          document.getElementById('image__2').classList.remove('image-hide')
          $('.overlay__cursors').fadeIn(500)
          $('.home__section').css('cursor', 'none')
          document.body.classList.remove('is-end')
          $('.header__right').fadeOut(250)
          $('.home__cursor-area').fadeIn(250)
          $('.home__logo').css('opacity', '1')
          $('.home__logo').fadeIn(250)
          break
        case '1':
          currentSectionIndex = 1
          document.getElementById('image__1').classList.add('image-hide')
          document.getElementById('image__2').classList.remove('image-hide')
          $('.overlay__cursors').fadeIn(500)
          $('.home__section').css('cursor', 'none')
          document.body.classList.remove('is-end')
          $('.header__right').fadeIn(250)
          $('.home__cursor-area').fadeIn(250)
          $('.home__logo').css('opacity', '1')
          $('.home__logo').fadeIn(250)
          break
        case '2':
          currentSectionIndex = 2
          document.getElementById('image__1').classList.add('image-hide')
          document.getElementById('image__2').classList.add('image-hide')
          $('.overlay__cursors').fadeIn(500)
          $('.home__section').css('cursor', 'none')
          document.body.classList.remove('is-end')
          // $('.home__logo').fadeOut(250)
          $('.home__cursor-area').fadeIn(250)
          // $('.header__right').fadeOut(250)
          break
        case 'end':
          currentSectionIndex = 3
          document.getElementById('image__1').classList.add('image-hide')
          document.getElementById('image__2').classList.add('image-hide')
          $('.overlay__cursors').fadeOut(125)
          $('.home__section').css('cursor', 'auto')
          if(window.innerWidth > 768) {
            $('.home__logo').fadeOut(300)
          }
          $('.home__cursor-area').fadeOut(250)
          $('.header__right').fadeIn(250)
          document.body.classList.add('is-end')
          break
        case 'projects':
          document.body.classList.remove('is-end')
      
        default:
          break
      }
     }
  })
}
let observer = new IntersectionObserver(onIntersection, options);
sections.forEach(image => observer.observe(image));