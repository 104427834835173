import disableScroll from 'disable-scroll';

const menu = document.querySelector('.menu')
const menuButton = document.getElementById('menuButton')
const creditsButton = document.getElementById('menuCreditsButton')

const menuClick = () => {
  document.body.classList.toggle('menu-active')
  document.body.classList.remove('is-scrolled')
  menuButton.classList.toggle('is-active')
  if (document.body.classList.contains('menu-active')) {
    disableScroll.on()
    document.querySelector('.site__logo').classList.add('is-expanded')
  } else {
    document.querySelector('.site__logo').classList.remove('is-expanded')
    disableScroll.off()
  } 
}

const creditsClick = () => {
  document.body.classList.toggle('credits-active')
}

document.querySelector('.menu__credits__wrapper').addEventListener('click', e => {
  if(e.target.tagName === 'DIV') {
    document.body.classList.remove('credits-active')
  }
})

menuButton.addEventListener('click', menuClick)
creditsButton.addEventListener('click', creditsClick)