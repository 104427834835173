import { renderOverlay, blockerFadeOut } from '../overlay/overlay'
import { renderBody } from './_render'


const onOverlayClick = (e, item) => {
  e.preventDefault()
  const jsonUrl = item.dataset.json
  renderOverlay(jsonUrl, 'project').then(([data, overlay]) => {
    renderBody(data, overlay)
      .then(blockerFadeOut)
  })
}

document.querySelectorAll('.overlay__link--project').forEach( item => {
  item.addEventListener('click', e => { onOverlayClick(e, item) })
})