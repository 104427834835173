import { lazyLoad } from './_images'
import './home/home'
import './archive/archive'
import './_menu'
import './projects/projects'
import './overlay/overlay'
import './home/_scroll'
import { editions__init } from './editions/editions'

window.onload = () => {
  lazyLoad()
  editions__init()
}