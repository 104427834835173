// Toggle

import $ from 'jquery';

if (document.body.classList.contains('template__archive')){
  document.querySelector('.archive__toggle--images').addEventListener('click', () => {
    toggleArchive('images')
  })
  document.querySelector('.archive__toggle--text').addEventListener('click', () => {
    toggleArchive('text')
  })
}

const toggleArchive = container => {
  const textContainer = document.querySelector('.archive__text')
  const imageContainer = document.querySelector('.archive__images')
  if (container === 'text') {
    document.querySelector('.archive__toggle--images').classList.remove('active')
    document.querySelector('.archive__toggle--text').classList.add('active')
    $(imageContainer).fadeOut(500, () => {
      $(textContainer).fadeIn(500)
    })
  }
  if (container === 'images') {
    document.querySelector('.archive__toggle--images').classList.add('active')
    document.querySelector('.archive__toggle--text').classList.remove('active')
    $(textContainer).fadeOut(500, () => {
      $(imageContainer).fadeIn(500)
    })
  }
}