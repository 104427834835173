import $ from 'jquery'
import html from 'nanohtml'
import { enableCursors, disableCursors } from './_cursors'
import { overlayArchiveHTML, overlayEditionHTML, overlayProjectHTML } from './_html'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let rememberScroll;

export const sustainOverlay = () => {
  return new Promise(resolve => {
    const sustainedBlocker = html`<div id="sustainedBlocker"></div>`
    document.body.appendChild(sustainedBlocker)
    $(sustainedBlocker).fadeIn(500, () => {
      resolve()
    })
  })
}
export const unSustainOverlay = () => {
  return new Promise(resolve => {
    $('#sustainedBlocker').fadeOut(0, () => {
      $('#sustainedBlocker').remove()
      resolve()
    })
  })
}

export const clearOverlay = (el) => {
  return new Promise (resolve => {
    const blocker = html`<div id="blocker"></div>`
    document.body.appendChild(blocker)
    $(blocker).fadeIn(500, () => {
      resolve()
    })
  })
}

export const showOverlay = (el) => {
  return new Promise(resolve => {
    $('.project__pagination').show(0)
    $('.overlay__content__header').show(0)
    $(el).show(0, () => {
      resolve()
    })
  })
}

export const closeArchive = (e, manual) => {
  return new Promise(resolve => {
    const overlay = document.querySelector('.overlay')
    const overlayClose = document.querySelector('.overlay__close')
    document.querySelector('.overlay__cursors').classList.remove('overlay__cursors--close')
    overlayClose.remove()
    clearOverlay()
      .then(() => {
        overlay.remove()
        document.body.classList.remove('archive-active')
        // enableBodyScroll(overlay)
        clearAllBodyScrollLocks()
        setTimeout(() => { window.scrollTo(0, rememberScroll) }, 10);
        disableCursors()
        $('#blocker').fadeOut(500, () => { 
          $(this).remove() 
          resolve()
        })
      })
  })
  
}

export const blockerFadeOut = () => {
  const blocker = document.getElementById('blocker')
  $(blocker).fadeOut(750, () => {
    blocker.remove()
  })
}

const renderOverlayByType = type => {
  let clone
  switch (type) {
    case 'archive':
      clone = overlayArchiveHTML.cloneNode(true)
      document.body.appendChild(clone)
      return clone
    case 'edition':
      clone = overlayEditionHTML.cloneNode(true)
      document.body.appendChild(clone)
      return clone
    case 'project':
      clone = overlayProjectHTML.cloneNode(true)
      document.body.appendChild(clone)
      return clone
    default:
      break;
  }
}

export const renderOverlay = async (jsonUrl, type) => {
  return new Promise (async resolve => {
    rememberScroll = window.scrollY
    const blocker = html`<div id="blocker"></div>`
    if (!document.getElementById('blocker')) { 
      document.body.appendChild(blocker) 
    } else {
      document.getElementById('blocker').remove() 
      document.body.appendChild(blocker) 
    }
    
    const overlay = renderOverlayByType(type)
    const overlayClose = overlay.querySelector('.overlay__close')
    let data = await getArchiveData(jsonUrl)
    document.body.classList.add('archive-active')
    $(blocker).fadeIn(500, () => {
      overlay.style.display = 'block'
      enableCursors()
      const scrollOverlay = overlay.querySelector('.overlay__content--scroll')
      disableBodyScroll(scrollOverlay)
      $(overlayClose).fadeIn(250)
      overlayClose.addEventListener('click', e => closeArchive(e, false))
      resolve([data, overlay])
    })
  })
}


const getArchiveData = async (jsonUrl) => {
  let response = await fetch(jsonUrl)
  let data = await response.json()
  return data
}

export const renderOverlayHTML = async (jsonUrl, type) => {
  return new Promise(async resolve => {
    rememberScroll = window.scrollY
    const blocker = html`<div id="blocker"></div>`
    if (!document.getElementById('blocker')) {
      document.body.appendChild(blocker)
    } else {
      document.getElementById('blocker').remove()
      document.body.appendChild(blocker)
    }
    const overlay = renderOverlayByType(type)
    const overlayClose = overlay.querySelector('.overlay__close')
    let data = await getArchiveDataHTML(jsonUrl)
    document.body.classList.add('archive-active')

    $(blocker).fadeIn(500, () => {
      overlay.style.display = 'block'
      enableCursors()
      disableBodyScroll(overlay)
      $(overlayClose).fadeIn(250)
      overlayClose.addEventListener('click', e => closeArchive(e, false))
      resolve([data, overlay])
    })
  })
}

const getArchiveDataHTML = async (jsonUrl) => {
  let response = await fetch(jsonUrl)
  let html = await response.text()
  const parser = new DOMParser()
  let data = await parser.parseFromString(html, 'text/html');
  return data
}
