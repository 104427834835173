import Swiper from 'swiper'
import { renderOverlayHTML, blockerFadeOut } from '../overlay/overlay'

const thumbnailHover = link => {
  const id = link.dataset.id
  document.querySelectorAll('.editions__contents__images__image').forEach(image => {
    image.dataset.id === id
      ? image.style.opacity = 1
      : image.style.opacity = 0
  })
}

const initSwiper = gallery => {
  const swiper = new Swiper(gallery, {
    speed: 500,
    spaceBetween: 100,
    direction: 'vertical',
    allowTouchMove: false,
    loop: false,
    navigation: {
      nextEl: gallery.querySelector('.swiper-button-next'),
      prevEl: gallery.querySelector('.swiper-button-prev'),
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    breakpoints: {
      768: {
        direction: 'horizontal',
        allowTouchMove: true
      }
    }
  })
  const nextButton = gallery.querySelector('.overlay__content__gallery__slide__next')
  const prevButton = gallery.querySelector('.overlay__content__gallery__slide__previous')
  prevButton.style.display = 'none'
  swiper.on('slideChange', function () {
    if (swiper.isBeginning) {
      prevButton.style.display = 'none'
      document.querySelector('.overlay__cursors').style.opacity = 0
    } else {
      prevButton.style.display = 'block'
    }
    if (swiper.isEnd) {
      nextButton.style.display = 'none'
      document.querySelector('.overlay__cursors').style.opacity = 0
    } else {
      nextButton.style.display = 'block'
    }
  })
  nextButton.addEventListener('click', e => {
    e.preventDefault()
    swiper.slideNext()
  })
  prevButton.addEventListener('click', e => {
    e.preventDefault()
    swiper.slidePrev()
  })

  const newImage = new Image()
  newImage.src = swiper.slides[0].querySelector('img').dataset.src
  newImage.onload = () => {
    blockerFadeOut()
  }

}

export const editions__init = () => {

  const galleries = document.querySelectorAll('.editions__section__gallery .swiper-container')
  galleries.forEach(gallery => { initSwiper(gallery) })
  document.querySelectorAll('.editions__contents__list__item').forEach(link => {
    link.addEventListener('mouseover', () => {
      thumbnailHover(link)
    })
  })

  const onLinkClick = (e, item) => {
    e.preventDefault()
    const jsonUrl = item.dataset.json
    renderOverlayHTML(jsonUrl, 'edition').then(([data, overlay]) => {
      const content = data.querySelector('.editions')
      overlay.appendChild(content)
      initSwiper(content.querySelector('.swiper-container'))
      // blockerFadeOut()
    })
  }

  document.querySelectorAll('.overlay__link--edition').forEach(item => {
    item.addEventListener('click', e => onLinkClick(e, item))
  })
  
}
