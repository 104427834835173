import html from 'nanohtml'
import raw from 'nanohtml/raw'
import $ from 'jquery'
import imagesLoaded from 'imagesloaded'
imagesLoaded.makeJQueryPlugin($);
import { sustainOverlay, unSustainOverlay, renderOverlay, blockerFadeOut, closeArchive } from '../overlay/overlay'
const renderBodyText = (block, el) => {
  const HTML = html`
  <div class="project__bodytext">
  ${raw(block.text)}
  </div>
  `
  el.appendChild(HTML)
}

const renderSingleImage = (block, el) => {
  const HTML = html`
  <div class="project__single-image">
  <figure>
    <img src="${block.image}">
    <figcaption class="text--small text--bold color--grey">${block.imagecaption}</figcaption>
  </figure>
  </div>
  `
  if (!block.imagecaption) {
    HTML.querySelector('figcaption').remove()
  }
  el.appendChild(HTML)
}

const renderDoubleImage = (block, el) => {
  const HTML = html`
  <div class="project__double-image text--center">
    <div class="row middle-sm">
      <div class="col-sm col-xs-12">
      <figure>
        <img src="${block.imageleft}">
        <figcaption class="text--small color--grey imageleftcaption">${block.imageleftcaption}</figcaption>
      </figure>
      </div>
      <div class="col-sm col-xs-12">
      <figure>
        <img src="${block.imageright}">
        <figcaption class="text--small color--grey imagerightcaption">${block.imagerightcaption}</figcaption>
      </figure>
      </div>
    </div>
  </div>
  `
  if (!block.imageleftcaption) {
    HTML.querySelector('.imageleftcaption').remove()
  }
  if (!block.imagerightcaption) {
    HTML.querySelector('.imagerightcaption').remove()
  }
  el.appendChild(HTML)
}

const resizeCaptions = images => {
  images.forEach(image => {
    const caption = image.img.parentNode.querySelector('figcaption')
    if (caption) { caption.style.width = image.img.offsetWidth + 'px' }
  })
}

const resizeCaptionsResize = images => {
  images.forEach(image => {
    const caption = image.parentNode.querySelector('figcaption')
    if (caption) { caption.style.width = image.offsetWidth + 'px' }
  })
}

const renderTitle = (data, overlay) => {
  const contentHeader = overlay.querySelector('.project__title')
  const text = `<span class="text--medium text--bold">${data.title}</span>`
  contentHeader.innerHTML = text
}

const loadNextProject = nextProject => {
  sustainOverlay()
  closeArchive()
    .then(() => {
      renderOverlay(nextProject, 'project').then(([data, overlay]) => {
        renderBody(data, overlay)
        // showOverlay(el).then(() => {
          blockerFadeOut()
          unSustainOverlay()
        // })
      })
    })
}

const renderPagination = (data, el) => {
  const pagination_element = document.querySelector('.project__pagination')
  pagination_element.innerHTML = ''

  const pagination = html` <div class="row"> </div> `
  if (data.previous) {
    const previous = html`
      <div class="col-xs text--center">
         <a class="project__pagination__link" href="${data.previous.url}">
          <span class="text--uppercase">previous</span><br>
          <span class="text--bold">${data.previous.title}</span>
        </a>
      </div>
    `
    previous.querySelector('.project__pagination__link').addEventListener('click', e => {
      e.preventDefault()
      loadNextProject(data.previous.url + '.json')
    })
    pagination.appendChild(previous)
  }
  if (data.next) {
    const next = html`
      <div class="col-xs text--center">
         <a class="project__pagination__link" href="${data.next.url}">
          <span class="text--uppercase">Next</span><br>
          <span class="text--bold">${data.next.title}</span>
        </a>
      </div>
    `
    next.querySelector('.project__pagination__link').addEventListener('click', e => {
      e.preventDefault()
      loadNextProject(data.next.url + '.json')
    })
    pagination.appendChild(next)
  }
  document.querySelector('.project__pagination').appendChild(pagination)
}


export const renderBody = (data, wrapper) => {
  return new Promise (resolve => {
    const el = wrapper.querySelector('.project__container')
    data.content.forEach(block => {
      switch (block.key) {
        case 'bodytext':
          renderBodyText(block, el)
          break;
        case 'singleimage':
          renderSingleImage(block, el)
          break;
        case 'doubleimage':
          renderDoubleImage(block, el)
          break;
        default:
          break;
      }
    })
    renderTitle(data, wrapper)
    renderPagination(data, el)

    $(wrapper).imagesLoaded().done(function (instance) {
        resizeCaptions(instance.images)
        instance.images.forEach(image => {
          image.img.style.opacity = 1
        })
      })

    window.addEventListener('resize', e => {
      resizeCaptionsResize(wrapper.querySelectorAll('img'))
    })
    resolve()
  })
}