import Rellax from 'rellax'

if (document.body.classList.contains('template__home')) { 
  new Rellax('.rellax', {
    center: false,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false
  })
}
