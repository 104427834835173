let currentTarget = 'none'

const changeCursor = (target, cursors) => {
  cursors.classList.remove('overlay__cursors--close')
  cursors.classList.remove('overlay__cursors--image-top')
  cursors.classList.remove('overlay__cursors--image-bottom')
  switch (target) {
    case 'close':
      cursors.classList.add('overlay__cursors--close')
      break;
    case 'image-top':
      cursors.classList.add('overlay__cursors--image-top')
      break;
    case 'image-bottom':
      cursors.classList.add('overlay__cursors--image-bottom')
      break;
    default:
      cursors.classList.remove('overlay__cursors--image-bottom')
      break;
  }
}

const overlayCursors = (e, cursors) => {
  let target = 'none'
  cursors.style.opacity = 0
  cursors.style.top = (e.clientY - cursors.offsetHeight / 2) + 'px'
  cursors.style.left = (e.clientX - cursors.offsetWidth / 2) + 'px'

  if (e.target.classList.contains('overlay__content__gallery__slide__previous')) {
    cursors.style.opacity = 1
    target = 'image-top'
    e.target.style.cursor = 'none'
  } 
  if (e.target.classList.contains('overlay__content__gallery__slide__next')) {
    cursors.style.opacity = 1
      target = 'image-bottom'
      e.target.style.cursor = 'none'
  }

  
  // if (e.target.classList.contains('overlay__close')) {
  //   cursors.style.opacity = 1
  //   cursors.style.opacity = '1!important'
  //   target = 'close'
  //   document.querySelector('.overlay__content').style.cursor = 'none'
  // } else {
  //   document.querySelector('.overlay__content').style.cursor = 'auto'
  // }

  if (currentTarget !== target) {
    changeCursor(target, cursors)
    currentTarget = target
  }
}

const onOverlayCursors = e => {
  overlayCursors(e, document.querySelector('.overlay__cursors'))
}

export const enableCursors = () => {
  window.addEventListener('mousemove', onOverlayCursors)
}
export const disableCursors = () => {
  window.removeEventListener('mousemove', onOverlayCursors)
  // const cursors = document.querySelector('.overlay__cursors')
  // cursors.classList.remove('overlay__cursors--close')
  // cursors.classList.remove('overlay__cursors--image-top')
  // cursors.classList.remove('overlay__cursors--image-bottom')
}