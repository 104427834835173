export const lazyLoad = () => {
  const imagesbackground = [...document.querySelectorAll('.lazy-image-background')];
  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px'
  }

  function onIntersection(imageEntities) {
    imageEntities.forEach(image => {
      if (image.isIntersecting) { loadBackground(image) }
    })
  }

  function onIntersectionImage(imageEntities) {
    imageEntities.forEach(image => {
      if (image.isIntersecting) { loadImage(image.target) }
    })
  }

  const loadBackground = image => {
    const fakeimage = new Image()
    fakeimage.onload = () => {
      image.target.style.background = `url(${image.target.dataset.src}) no-repeat center center`
      image.target.style.backgroundSize = `cover`
      image.target.style.opacity = `1`
    }
    fakeimage.src = image.target.dataset.src
  }

  const loadImage = image => {
    observerImages.unobserve(image)
    const newImage = new Image()
    newImage.src = image.dataset.src
    newImage.onload = (e) => {
      image.src = image.dataset.src
      setTimeout(() => {
        image.style.opacity = 1
        image.classList.remove('archive__image--blur')
      }, 250);
    }
  }


  let observer = new IntersectionObserver(onIntersection, options);
  imagesbackground.forEach(image => observer.observe(image));
  
  const images = [...document.querySelectorAll('.lazy-image')];
  let observerImages = new IntersectionObserver(onIntersectionImage, options);
  images.forEach(image => observerImages.observe(image));


}
