import Swiper from 'swiper'
import $ from 'jquery'
import html from 'nanohtml'
import raw from 'nanohtml/raw'
import imagesLoaded from 'imagesloaded'
imagesLoaded.makeJQueryPlugin($);
import { renderOverlay, closeArchive, blockerFadeOut } from '../overlay/overlay'
import './_toggle'

let swiperInstance

const initSwiper = swipercontainer => {
  return new Promise(resolve => {
    swiperInstance = new Swiper(swipercontainer, {
      speed: 500,
      spaceBetween: 100,
      direction: 'vertical',
      allowTouchMove: false,
      breakpoints: {
        768: {
          direction: "horizontal",
          allowTouchMove: true
        }
      }
    })
    resolve(swiperInstance)
  })
}

const addImages = (swiper, images) => {
  const slides = []
  images.forEach(image => {
    const hiRes = image[1]
    const slide = html`
    <div class="swiper-slide overlay__content__gallery__slide">
      <img class="swiper-lazy swiper-slide-image" src="${hiRes}">
    </div>
    `

    
    slides.push(slide)

    // Slide Previous
    // slide.querySelector('.overlay__content__gallery__slide__previous').addEventListener('click', e => {
    //   e.preventDefault()
    //   swiperInstance.slidePrev()
    // })

    // Slide Next
    // slide.querySelector('.overlay__content__gallery__slide__next').addEventListener('click', e => {
    //   e.preventDefault()
    //   swiperInstance.slideNext()
    // })

    imagesLoaded(slide, () => {
      swiper.update()
    })

  })
  // slides[0].querySelector('.overlay__content__gallery__slide__previous').remove()
  // slides[slides.length - 1].querySelector('.overlay__content__gallery__slide__next').remove()
  swiper.appendSlide(slides)

  // if (window.innerWidth < 768) {
  //   const text = html`
  //     <div class="swiper-slide overlay__content__gallery__slide overlay__content__gallery__slide--text">
  //       ${raw(document.querySelector('.overlay__content__text').innerHTML)}
  //     </div>
  //   `
  //   swiper.appendSlide(text)
  // }
  swiper.update()
  swiper.el.style.opacity = 1
  const newImage = new Image()
  newImage.src = slides[0].querySelector('img').src
  newImage.onload = () => {
    blockerFadeOut()
  }
}

const renderGallery = (images, overlay) => {
  const swiper = overlay.querySelector('.swiper-container')
  initSwiper(swiper)
    .then(swiper => addImages(swiper, images))
    const nextButton = overlay.querySelector('.overlay__content__gallery__slide__next')
    const prevButton = overlay.querySelector('.overlay__content__gallery__slide__previous')
    prevButton.style.display = 'none'
    swiper.swiper.on('slideChange', function(){
      if (swiperInstance.isBeginning) {
        prevButton.style.display = 'none'
        document.querySelector('.overlay__cursors').style.opacity = 0
      } else {
        prevButton.style.display = 'block'
      }
      if (swiperInstance.isEnd) {
        nextButton.style.display = 'none'
        document.querySelector('.overlay__cursors').style.opacity = 0
      } else {
        nextButton.style.display = 'block'
      }
    })
    nextButton.addEventListener('click', e => {
      e.preventDefault()
      swiperInstance.slideNext()
    })
    prevButton.addEventListener('click', e => {
      e.preventDefault()
      swiperInstance.slidePrev()
    })
  swiper.swiper.update()
}

const renderText = (data, overlay) => {
  const contentText = overlay.querySelector('.overlay__content__text')
  contentText.innerHTML = data.text
}

const renderTitle = (data, overlay) => {
  const contentHeader = overlay.querySelector('.overlay__content__header__title')
  $(contentHeader).hide()
  const text = `<span class="text--bold">${data.title}</span> ${data.location}, ${data.country}`
  contentHeader.innerHTML = text
  $(contentHeader).fadeIn(250)
}

const onOverlayClick = item => {
  const jsonUrl = item.dataset.json
  renderOverlay(jsonUrl, 'archive').then(async ([data, overlay]) => {
    renderTitle(data, overlay)
    renderText(data, overlay)
    renderGallery(data.images, overlay)
  })
}

const onImageMouseEnter = () => {
  document.querySelector('.archive__images').classList.add('archive__images--is-hover')
}
const onImageMouseLeave = () => {
  document.querySelector('.archive__images').classList.remove('archive__images--is-hover')
}

document.querySelectorAll('.overlay__link--archive').forEach(item => {
  item.addEventListener('click', e => { 
    e.preventDefault()
    onOverlayClick(item) 
  })
})

document.querySelectorAll('.archive__image').forEach(item => {
  item.addEventListener('mouseenter', onImageMouseEnter)
  item.addEventListener('mouseleave', onImageMouseLeave)
})


