const leadingImages = document.querySelectorAll('.home__leading-image')

leadingImages.forEach(image => {

  const loadImage = new Image()

  loadImage.onload = function (e) {
    window.innerWidth < 768
      ? image.style.background = `url(${image.dataset.background}) no-repeat center center`
      : image.style.background = `url(${image.dataset.background}) no-repeat center center fixed`
    image.style.backgroundSize = `cover`
    image.style.opacity = 1
  }

  loadImage.src = image.dataset.background

})