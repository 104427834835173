import html from 'nanohtml'
import raw from 'nanohtml/raw'

const closeSVG = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="32px" height="32px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#222222;}
  </style>
  <polygon class="st0" points="26.7,6.7 25.3,5.3 16,14.6 6.7,5.3 5.3,6.7 14.6,16 5.3,25.3 6.7,26.7 16,17.4 25.3,26.7 26.7,25.3 
    17.4,16 "/>
  </svg>
`
const arrowSVG = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="32px" height="32px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#222222;}
  </style>
  <polygon class="st0" points="0.9,10.6 0.9,13.1 15.9,21.4 31.1,13.1 31.1,10.6 15.9,18.9 "/>
  </svg>
`

export const overlayArchiveHTML = html`
  <div class="overlay">
    <div class="overlay__content">
      <div class="overlay__content__header">
        <div class="overlay__content__header__title"></div>
      </div>
      <div class="overlay__content__gallery">
        <div class="swiper-container">
          <div class="swiper-wrapper"> </div>
          <div class="overlay__content__gallery__slide__previous"></div>
          <div class="overlay__content__gallery__slide__next"></div>
        </div>
      </div>
      <div class="overlay__content__text__wrapper">
        <div class="overlay__content__text text--small text--bold"></div>
      </div>
      <div class="overlay__cursors">
        <div class="overlay__cursor overlay__cursor__up"> ${raw(arrowSVG)} </div>
        <div class="overlay__cursor overlay__cursor__down"> ${raw(arrowSVG)} </div>
        <div class="overlay__cursor overlay__cursor__close"> ${raw(closeSVG)} </div>
      </div>
      <div class="overlay__close"> ${raw(closeSVG)} </div>
    </div>
  </div>
`

export const overlayEditionHTML = html`
  <div class="overlay">
    <div class="overlay__content project__content">
      <div class="overlay__content__header">
        <div class="overlay__content__header__title"></div>
      </div>
      <div class="overlay__close"> ${raw(closeSVG)} </div>
      <div class="overlay__cursors">
        <div class="overlay__cursor overlay__cursor__up"> ${raw(arrowSVG)} </div>
        <div class="overlay__cursor overlay__cursor__down"> ${raw(arrowSVG)} </div>
        <div class="overlay__cursor overlay__cursor__close"> ${raw(closeSVG)} </div>
      </div>
    </div>  
  </div>  
`

export const overlayProjectHTML = html`
  <div class="overlay">
    <div class="overlay__content  overlay__content--scroll project__content">
      <div class="overlay__content__header">
        <div class="overlay__content__header__title"></div>
      </div>
      <div class="main project">
        <div class="project__title text--center"></div>
        <div class="project__container"></div>
        <div class="project__pagination"> </div>
      </div>
    </div>
    <div class="overlay__close"> ${raw(closeSVG)} </div>
    <div class="overlay__cursors">
      <div class="overlay__cursor overlay__cursor__up"> ${raw(arrowSVG)} </div>
      <div class="overlay__cursor overlay__cursor__down"> ${raw(arrowSVG)} </div>
      <div class="overlay__cursor overlay__cursor__close"> ${raw(closeSVG)} </div>
    </div>
  </div>
`